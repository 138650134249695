<template>
  <div class="row">
    <div
      class="col-sm-6 col-12 mt-4 pt-2 px-2"
      v-for="(item, index) of pricingData"
      :key="index"
    >
      <div
        class="card pricing-rates business-rate shadow bg-light rounded text-center border-0"
      >
        <div
          class="ribbon ribbon-right ribbon-warning overflow-hidden"
          v-if="item.isBest === true"
        >
          <span class="text-center d-block shadow small h6">Econômico</span>
        </div>

        <div class="card-body py-5 px-1">
          <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
            {{ item.title }}
          </h6>
          <div class="d-flex justify-content-center mb-0">
            <span class="h5 mb-0 mt-2">R$</span>
            <span class="price h1 mb-0">{{ item.priceMonth }}</span>
            <span class="h6 align-self-end mb-1">{{ [5, 6, 7].includes(item.id) ? '/Processo' : '/mês' }}</span>
          </div>
          <div class="d-flex justify-content-center mb-4">
            <span style="font-size: 11px" class="mb-0 text-muted">Pagamento Único - R$ {{ item.priceTotal }}</span>
          </div>

          <ul class="list-unstyled text-left mb-0 pl-0">
            <li
              class="small text-muted mb-0"
              v-for="(feature, index) in item.feature"
              :key="index"
            >
              <span class="text-primary h5 mr-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >{{ feature }}
            </li>
          </ul>
          <a href="javascript:void(0)" @click="choosePlan(item.id)" class="btn btn-primary mt-4">
            Cadastrar
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    pricingData() {
      const firstPlan = {
        id: 5,
        title: "Por Processo",
        priceMonth: 199,
        priceTotal: '199,00',
        feature: [
          "Suporte via WhatsApp ou E-mail",
          "Gestão de Processos",
          "Avisos de Processos em Aberto",
          "Acesso ilimitado 24hrs por dia",
          "Participação para um Processo",
          "Treinamento Online para Participação",
        ],
      }

      const host = window.location.host.replace('www.', '')
      if(['licitaalema.com.br'].includes(host)) {
        firstPlan.id = 6
        firstPlan.priceMonth = 199
        firstPlan.priceTotal = '199,00'
      }

      return [
        firstPlan,
        {
          id: 7,
          title: "Dispensa",
          priceMonth: 99,
          priceTotal: '99,00',
          feature: [
            "Suporte via WhatsApp ou E-mail",
            "Gestão de Processos",
            "Avisos de Processos em Aberto",
            "Acesso ilimitado 24hrs por dia",
            "Participação para uma Dispensa",
            "Treinamento Online para Participação",
          ],
        }
      ]
    }
  },
  methods: {
    async choosePlan(planId) {
      await this.$router.push({ name: 'fornecedor' })
      // await this.$router.push({ name: 'fornecedor', query: { planId: planId } })
    },
  }
};
</script>