<template>
  <div>

    <section class="bg-half bg-soft-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1 class="title mb-0">Escolha o melhor plano</h1>
              <h5 class="text-muted">Selecione o plano que melhor se enquadra com sua necessidade.</h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="section pt-5 pb-5">
      <div class="container">
        <Pricing />
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-light">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <div class="position-relative">
      <div class="shape overflow-hidden text-footer">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import {
  HelpCircleIcon,
} from "vue-feather-icons";

import Pricing from "@/components/Pricing";

export default {
  components: {
    Carousel,
    Slide,
    HelpCircleIcon,
    Pricing
  },
  data() {
    return {
      searchText: '',
    }
  },
  computed: {
    title() {
      return this.$store.getters['layout/title']
    },
    organizationName() {
      return this.$store.getters['layout/organizationName']
    },
    messageWhatsApp() {
      const organizationName = this.$store.getters['layout/title']

      return `${'https://api.whatsapp.com/send?phone=5599984449559&text=Olá, estou com dúvidas sobre o Portal de Licitação da ' + organizationName + '.'}`
    }
  },
  methods: {
    async search() {
      await this.$router.push({ name: 'processos', query: { search: this.searchText } })
    }
  },
};
</script>

